import React from 'react';

import Orders from './Orders';

const Admin = () => {
  return (
    <Orders />
  )
}

export default Admin;
